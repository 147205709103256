#bg-blur {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(32,32,32, 0.4);
  z-index: -1;
  -webkit-backdrop-filter: blur(250px);
  backdrop-filter: blur(250px);
}

nav {
  padding: 36px;
  width: 272px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  box-sizing: border-box;
  background: #383838c7;
  position: fixed;
  z-index: 99;
}

@media (max-width: 1000px) {
  nav {
    width: 100%;
    display: none;
  }
}

nav h1 {
  margin: 0 auto;
  font-size: 32px;
  background: linear-gradient(90deg, #EEC48B, #947A58);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: fit-content;
}

nav div {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 32px 0 auto 0;
}

nav a {
  text-decoration: none;
  color: #9F9F9F;
  font-size: 14px;
  padding: 10px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
}

nav a:hover {
  background: rgba(159,159,159, 0.2);
}

nav a svg {
  display: inline-block;
  height: 20px;
  width: 20px;
  margin-right: 10px;
}

.active-page {
  color: #EEC48B;
  background: rgba(159,159,159, 0.1);
}
.active-page span {
  background: #EEC48B;
}

nav button {
  font-size: 14px;
  font-weight: 700;
  width: 100%;
  padding: 12px;
  border-radius: 5px;
  border: none;
  background: linear-gradient(90deg, #E1E1E1, #8F8F8F);
  cursor: pointer;
}