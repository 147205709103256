.banner {
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  position:absolute;
  overflow: hidden;
  background: transparent;
  background: linear-gradient(
    to right,
    #6f7280,
    #2a2c3c,
    #181827,
    #833ab4,
    #fd1d1d,
    #fcb045
  );
  background-size: 400% 400%;
  animation: banner 30s infinite ease-in-out;
}

.title {
  width: 80%;
  max-width: 350px;
  position: relative;
  left: calc(50% + 136px);
  top: 10%;
  transform: translateX(-50%);
}

@keyframes banner {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

#qrCodeBox {
  height: 470px;
  width: 355px;
  background: #313131;
  border-radius: 20px 20px 20px 20px;
  box-shadow: 0px 0px 50px #181818;
  margin: 0;
  position: absolute;
  top: 50%;
  left: calc(50% + 136px);
  transform: translate(-50%, -50%);
}

@media (max-width: 1000px) {
  #qrCodeBox {
    left: 50%;
    transform: translate(-50%, -50%) scale(.7); /*make qr box smaller for easier scan*/
  }

  .title {
    left: 50%;
  }
}

@media (max-height: 850px) {
  .title {
    top: 5%;
  }
}

#qrCodeSquareFrame {
  width: 320px;
  height: 320px;
  background: #fff;
  border-radius: 10px;
  margin: 17px;
  border: none;
  position: relative;
}

.centered {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.centered img {
  border-radius: .5rem;
}

#qrcode {
  mix-blend-mode: screen;
}

.text {
  width: 90%;
  flex-direction: column;
  text-align: center;
  font-size: 18px;
  margin: 10px;
  background: linear-gradient(90deg, #EEC48B, #947A58);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#one {
  font-weight: 700;
  font-size: 30px;
}

#two {
  margin-top: -7px;
}